<template>
	<el-dialog :title="!dataForm.fileId ? '发文登记' : '发文修改'" :close-on-click-modal="false" :visible.sync="visible"
		width="820px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="110px">
			<el-row>
				<el-col :span="12">
				<el-form-item label="发文类型" prop="fileKind2">
					<el-select class="selItemInput" v-model="dataForm.fileKind2" placeholder="请选择">
						<el-option v-for="item in documentTypeList" :key="item.displayValue" :label="item.displayName"
								   :value="item.displayValue">
						</el-option>
					</el-select>
				</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发文编号" prop="fileNo">
						<el-input class="selItemInput" v-model="dataForm.fileNo" placeholder="文件编号" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="文件主题" prop="fileTitle">
						<el-input type="textarea" class="selItemInput descAreaLg" rows="3" v-model="dataForm.fileTitle" placeholder=""></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发文人" prop="dispMan">
						<el-input class="selItemInput" v-model="dataForm.dispMan" placeholder="" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发文时间" prop="dispTime">
						<el-date-picker  class="selItemInput" v-model="dataForm.dispTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发文对象" prop="receDispCompany">
						<el-input class="selItemInput" rows="1" v-model="dataForm.receDispCompany" placeholder=""></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="是否需要回复" prop="answerNeedFlg">
						<el-select class="selItemInput" v-model="dataForm.answerNeedFlg" placeholder="请选择">
							<el-option
									v-for="item in answerNeedList"
									:key="item.value"
									:label="item.name"
									:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="扫描件">
						<div class="addFileBox" v-if="dataForm.filePath">
							<div>
								<el-button v-preventReClick type="success" size="small" @click="selectFile()">查看</el-button>
								<el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
							</div>
						</div>
						<el-upload
								class="addFileBox"
								ref="uploadFile"
								:action="action"
								v-if="!dataForm.filePath"
								:http-request="uploadFile">
							<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add" style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary"  @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "superdocument-add-or-update",
		data() {
			return {
				visible: false,
				inline: false,
				action: '',
				documentTypeList: [],
				answerNeedList: [{
					name: '不需要',
					value: '0'
				},{
					name: '需要',
					value: '1'
				}
				],
				fileList:[],
				dataForm: {
					workNo: $common.getItem("workNo"),
					subjectNo:$common.getItem("subjectNo"),
					fileId: '',
					fileNo: '',
					dispMan: '',
					fileTitle: '',
					receDispCompany: '',
					filePath: '',
					fileUri: '',
					fileKind2: '',
					dispTime: '',
					answerNeedFlg: '',
					// exaFlgName: '',
				},
				dataRule: {
					fileKind2: [
						{ required: true, message: "请选择发文类型", trigger: "change" }
					],
					fileNo: [
						{ required: true, message: "请输入发文编号", trigger: "blur" }
					],
					fileTitle: [
						{ required: true, message: "请输入文件主题", trigger: "blur" }
					],
					dispMan: [
						{ required: true, message: "请输入发件人", trigger: "blur" }
					],
					dispTime: [
						{ required: true, message: "请选择发文时间", trigger: "change" }
					],
					receDispCompany: [
						{ required: true, message: "请输入发文对象", trigger: "blur" }
					],
					answerNeedFlg: [
						{ required: true, message: "请选择是否需要回复", trigger: "change" }
					],
				},
			}
		},
		methods: {
			init(id) {
				this.getSubjectDropDown();
				this.dataForm = {
					workNo: $common.getItem("workNo"),
					subjectNo:$common.getItem("subjectNo"),
					fileId: '',
					fileNo: '',
					dispMan: '',
					fileUri: '',
					fileTitle: '',
					receDispCompany: '',
					filePath: '',
					fileKind2: '',
					dispTime: '',
					answerNeedFlg: '',
					        // exaFlgName: '',
				};
				this.dataForm.fileId = id?id:'';
				if (this.dataForm.fileId) {
					this.$http({
						url:  this.$store.state.httpUrl +`/business/fileinfo/info/${this.dataForm.fileId}`,
						method: 'get',
						params: {}
					}).then(({data}) => {
						if (data && data.resultCode === 200) {
							this.dataForm = data.body;
							// this.dataForm.fileNo = data.body.fileNo;
							// this.dataForm.dispMan = data.body.dispMan;
							// this.dataForm.fileTitle = data.body.fileTitle;
							// this.dataForm.receDispCompany = data.body.receDispCompany;
							// this.dataForm.filePath = data.body.filePath;
							// this.dataForm.fileUri = data.body.fileUri;
							// this.dataForm.fileKind2 = data.body.fileKind2;
							// this.dataForm.dispTime = data.body.dispTime;
							// this.dataForm.answerNeedFlg = data.body.answerNeedFlg;
						}
					})
				}
				this.$nextTick(() => {
					this.visible = true;
				});
				this.$refs['dataForm'].resetFields();
			},
			selectFile(){
				window.open(this.dataForm.fileUri);
			},
			delFile(){
				this.dataForm.filePath = "";
				this.dataForm.fileUri = "";
			},
			uploadFile(param){
				if (!this.dataForm.fileKind2){
					this.$message.info('请选择文件类型');
					return;
				}
				const formData = new FormData();
				formData.append('file', param.file);
				this.$refs.uploadFile.clearFiles();
				this.$http.post(
						this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/20/"+this.dataForm.fileKind2,
						formData,
						{
							headers: { 'Content-Type': 'multipart/form-data' }
						},
				).then(res => {
					res = res.data
					if (res.resultCode === 200){
						this.dataForm.filePath = res.body.fileSavePath;
						this.fileList = [{
							fileName: res.body.fileName,
							filePath: res.body.fileSavePath,
							fileUri: res.body.fileUri
						}];
					} else {
						this.$message.info('文件上传失败')
					}
				})
			},
			//获取发文类型
			getSubjectDropDown() {
				this.$http({
					url:  this.$store.state.httpUrl + "/common/getSendDocumentType/"+$common.getItem("workNo")+"/"+$common.getItem("subjectNo"),
					method: "get",
					params: {},
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.documentTypeList = data.body;
						this.dataForm.fileKind2 = this.documentTypeList[0].displayValue;
					}
				})
			},
			//表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						let _url = "/business/fileinfo/saveSend";
						if(this.dataForm.fileId && this.dataForm.fileId != 0){
							_url = "/business/fileinfo/updateFileInfoSend";
						}
						this.$http({
							url: this.$store.state.httpUrl + _url,
							method: "post",
							data: this.$http.adornData(this.dataForm)
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			}
		}
	}
</script>
<style scoped>
	.selItemInput.descAreaLg {
		width: 640px !important;
	}
</style>
